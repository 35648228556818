import React from 'react'
import style from './WidgetHeading.module.css'

interface WidgetHeadingProps {
  children: string
  mb?: number
}

export default function WidgetHeading({ children, mb }: WidgetHeadingProps) {
  return (
    <div className={style.root} style={{ marginBottom: mb || 0 }}>
      {children}
    </div>
  )
}
