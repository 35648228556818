import {
  AppState,
  ActionTypes,
  CLEAR_APP_ERROR,
  GET_COUNTERS,
  SET_COUNTERS,
  SET_APP_ERROR,
  SET_TOKEN,
  SET_GOOGLE_TOKEN,
  SET_LABElS,
  SET_STATS,
  SET_APP_DATA,
} from './types'
import { mockCounters, mockCountersExtra } from '../mocks/mocks'
import { LocalStorage } from '../utils/localStorage'

const initialState: AppState = {
  // counters: mockCounters,
  labels: [],
  googleToken: LocalStorage.getGoogleToken(),
  isGoogleLoggedIn: false,
  token: '',
  isLoading: false,
  appError: undefined,
}

export function appReducer(
  state = initialState,
  action: ActionTypes,
): AppState {
  switch (action.type) {
    case SET_APP_DATA:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      }
    case SET_GOOGLE_TOKEN:
      return {
        ...state,
        googleToken: action.payload,
      }
    case GET_COUNTERS:
      return {
        ...state,
        isLoading: true,
      }
    case SET_LABElS:
      return {
        ...state,
        labels: action.payload,
      }
    case SET_STATS:
      return {
        ...state,
        stats: action.payload,
      }
    case SET_COUNTERS:
      return {
        ...state,
        counters: action.payload,
        isLoading: false,
      }
    case SET_APP_ERROR:
      return {
        ...state,
        appError: action.payload,
        isLoading: false,
      }
    case CLEAR_APP_ERROR:
      return {
        ...state,
        appError: undefined,
      }
    default:
      return state
  }
}
