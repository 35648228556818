import {
  GetCountersResponse,
  GetLabelsResponse,
  GetStatsResponse,
} from '../@types/api'

export const SET_TOKEN = 'SET_TOKEN'
export const GET_STATS = 'GET_STATS'
export const SET_GOOGLE_TOKEN = 'SET_GOOGLE_TOKEN'
export const GET_COUNTERS = 'GET_COUNTERS'
export const GET_ALL_COUNTERS = 'GET_ALL_COUNTERS'
export const GET_LABELS = 'GET_LABELS'
export const SET_COUNTERS = 'SET_COUNTERS'
export const SET_LABElS = 'SET_LABElS'
export const SET_STATS = 'SET_STATS'
export const SET_APP_DATA = 'SET_APP_DATA'
export const SET_IS_LOADING = 'SET_IS_LOADING'
export const SET_APP_ERROR = 'SET_APP_ERROR'
export const CLEAR_APP_ERROR = 'CLEAR_APP_ERROR'

export enum AppError {
  IncorrectToken = 'IncorrectToken',
  Forbidden = 'Forbidden',
  InternalServerError = 'InternalServerError',
  ServiceUnavailable = 'ServiceUnavailable',
  NullResponse = 'NullResponse',
  Other = 'Other',
}

export interface AppState {
  counters?: Counters
  healthcheck?: boolean
  stats?: Stats
  labels?: string[]
  googleToken: string | null
  isGoogleLoggedIn: boolean
  token: string
  isLoading: boolean
  appError?: AppError
}

export interface SetAppErrorAction {
  type: typeof SET_APP_ERROR
  payload: AppError
}

export interface SetCounters {
  type: typeof SET_COUNTERS
  payload: CountersPayload
}

export interface SetLabels {
  type: typeof SET_LABElS
  payload: LabelsPayload
}

export interface SetStats {
  type: typeof SET_STATS
  payload: StatsPayload
}

export interface SetAppData {
  type: typeof SET_APP_DATA
  payload: AppDataPayload
}

export interface SetIsGoogleLoggedIn {
  type: typeof SET_GOOGLE_TOKEN
  payload: string
}

export interface SetToken {
  type: typeof SET_TOKEN
  payload: string
}

export interface GetCounters {
  type: typeof GET_COUNTERS
}

export interface GetAllCounters {
  type: typeof GET_ALL_COUNTERS
}

export interface GetLabels {
  type: typeof GET_LABELS
}

export interface GetStats {
  type: typeof GET_STATS
}

export interface ClearAppErrorAction {
  type: typeof CLEAR_APP_ERROR
}

export interface Counters extends GetCountersResponse {}
export interface Stats extends GetStatsResponse {}
export interface CountersPayload extends GetCountersResponse {}
export interface LabelsPayload extends GetLabelsResponse {}
export interface StatsPayload extends GetStatsResponse {}

export interface AppDataPayload {
  stats?: StatsPayload
  labels?: LabelsPayload
  counters?: CountersPayload
  healthcheck?: boolean
}

export type ActionTypes =
  | SetAppErrorAction
  | ClearAppErrorAction
  | GetCounters
  | SetCounters
  | SetToken
  | SetIsGoogleLoggedIn
  | GetStats
  | SetStats
  | GetLabels
  | SetLabels
  | SetAppData
  | GetAllCounters
