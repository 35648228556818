import React from 'react'
import style from './MonthlyWidget.module.css'
import {
  LineChart,
  XAxis,
  CartesianGrid,
  Tooltip,
  Line,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts'
import moment from 'moment'
import { NameType } from 'recharts/types/component/DefaultTooltipContent'
const today = moment()

export interface MonthlyWidgetProps {
  total: number
  limit: number
  data: {
    date: string
    count: number
  }[]
}

const tooltipFormatter: (
  value: string | number | Array<string | number>,
  name: string,
  entry: any,
  index: number,
) => React.ReactNode = (
  value: string | number | Array<string | number>,
  name: string,
  entry: any,
  index: number,
) => {
  return [value, 'кол-во']
}

export default function MonthlyWidget({
  total,
  limit,
  data,
}: MonthlyWidgetProps) {
  const CustomTooltip = (props: TooltipProps<string, NameType>) => {
    const { active, payload } = props

    if (active && payload && payload.length > 0) {
      return <div className={style.tooltip}>{payload[0].value}</div>
    }

    return null
  }

  const CustomizedAxisTick = (props: any) => {
    const { x, y, payload } = props

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={-6}
          y={0}
          dy={16}
          textAnchor="center"
          className={style.tickText}
        >
          {payload.value}
        </text>
      </g>
    )
  }

  const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
  })
  return (
    <div className={style.root}>
      <div className={style.status}>
        <div className={style.heading}>
          <span className={style.circle}></span>Лицензия активна
        </div>
        <div className={style.capacity}>
          <span className={style.recognized}>{formatter.format(total)}</span> /{' '}
          {formatter.format(limit)}{' '}
          <span className={style.period}>за {today.format('MMMM')}</span>
        </div>
        <div className={style.graph}>
          <ResponsiveContainer width={'100%'} height={180}>
            <LineChart
              data={data}
              margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
            >
              <XAxis dataKey="date" tick={<CustomizedAxisTick />} />
              <Tooltip formatter={tooltipFormatter} content={CustomTooltip} />
              <CartesianGrid stroke="#AAAAAA" vertical={false} />
              <Line
                type="monotone"
                dataKey="count"
                stroke="#1356A8"
                strokeWidth="4px"
                yAxisId={0}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}
