import React, { useEffect } from 'react'
import ReactGoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
// import { getClients, setGoogleToken } from '../../store/actions'
// import { State } from '../../store/types'
import { LocalStorage } from '../../utils/localStorage'
import style from './GoogleLoginButton.module.css'
import axios from 'axios'
import { Button, ButtonTheme } from '@dbrainio/shared-ui'

export default function GoogleLoginButton({
  onLogin,
}: // onLogin,
{
  onLogin: (res?: GoogleLoginResponse) => void
}) {
  // const googleToken = useSelector<State, string | null>(
  //   (state: State) => state.app.googleToken,
  // )

  const dispatch = useDispatch()
  const history = useHistory()

  const handleSuccess = ({ tokenId }: GoogleLoginResponse) => {
    axios.defaults.headers.common = { Authorization: `Bearer ${tokenId}` }

    // dispatch(setGoogleToken(tokenId))
    LocalStorage.setGoogleToken(tokenId)
    onLogin()
    history.push('/admin')
  }

  return (
    <ReactGoogleLogin
      clientId="729722268284-resvfi19ib6v2su32v4qk0958gt023ia.apps.googleusercontent.com"
      buttonText="Login"
      render={(renderProps) => (
        <Button
          theme={ButtonTheme.Blue}
          className={style.submit}
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >
          <span className={style.buttonText}>Войти через Google</span>
        </Button>
      )}
      onSuccess={(
        response: GoogleLoginResponse | GoogleLoginResponseOffline,
      ) => {
        handleSuccess(response as GoogleLoginResponse)
        // onLogin(response as GoogleLoginResponse)
      }}
      onFailure={() => {
        console.log('failure')
      }}
      cookiePolicy={'single_host_origin'}
    />
  )
}
