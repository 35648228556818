import React from 'react'
import style from './StatusWidget.module.css'
import classnames from 'classnames'

interface StatusWidgetProps {
  headerLabels: string[]
  status: boolean
  renderFooterContent: () => React.ReactNode
}

export default function StatusWidget({
  headerLabels,
  renderFooterContent,
  status,
}: StatusWidgetProps) {
  return (
    <div
      className={
        status
          ? classnames(style.root, style.on)
          : classnames(style.root, style.off)
      }
    >
      <div className={style.header}>
        {status ? headerLabels[0] : headerLabels[1]}
      </div>
      <div className={style.footer}>{renderFooterContent()}</div>
    </div>
  )
}
