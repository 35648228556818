import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import store from './store'
import { BrowserRouter as Router } from 'react-router-dom'
import 'moment/locale/ru'
import moment from 'moment'
import { Provider as Jotai } from 'jotai'

moment.locale('ru')

ReactDOM.render(
  <React.StrictMode>
    <Jotai>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </Jotai>
  </React.StrictMode>,
  document.getElementById('root'),
)
