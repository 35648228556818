import React from 'react'
import style from './Header.module.css'
import Logo from '../Logo/Logo'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

interface HeaderProps {
  transparent?: boolean
}

export default function Header({ transparent }: HeaderProps) {
  const history = useHistory()

  const renderLogo = () => (
    <div onClick={() => history.push('/')} className={style.logo}>
      <Logo />
    </div>
  )
  return (
    <div className={classNames(style.root, transparent && style.transparent)}>
      {transparent ? (
        renderLogo()
      ) : (
        <Container>
          <Grid container justify="flex-start">
            {renderLogo()}
          </Grid>
        </Container>
      )}
    </div>
  )
}
