import React, { useState, useEffect } from 'react'
import Container from '@material-ui/core/Container'
import StatusWidget from '../StatusWidget/StatusWidget'
import style from './StatusScreen.module.css'
import MonthlyWidget from '../MonthlyWidget/MonthlyWidget'
import FrequencyWidget from '../FrequencyWidget/FrequencyWidget'
import TimeWidget from '../TimeWidget/TimeWidget'
import { Counters, Stats } from '../../store/types'
import { CountersCommon } from '../../@types/api'
import { useHistory } from 'react-router-dom'
import { Utils } from '../../utils/utils'

interface StatusScreenProps {
  counters?: Counters
  stats?: Stats
  labels?: string[]
  healthcheck?: boolean
}

export default function StatusScreen({
  counters,
  labels,
  stats,
  healthcheck,
}: StatusScreenProps) {
  const [status, setStatus] = useState(true)
  // const [hitl, setHitl] = useState(false)
  const history = useHistory()
  // const matches = useMediaQuery('(max-width:600px)')
  const isHitl = !!labels?.includes('hitl')

  const getTimeWidgetStats = (stats: Stats) => {
    return [
      {
        label: 'Макс.',
        // TODO: need to be changeed to 95 after https://dbrain.atlassian.net/browse/DEV-306
        time: Math.round(stats?.processing_time_p5),
      },
      {
        label: 'Медиана',
        time: Math.round(stats?.processing_time_med),
      },
      {
        label: 'Мин.',
        // TODO: need to be changeed to 5 after https://dbrain.atlassian.net/browse/DEV-306
        time: Math.round(stats?.processing_time_p95),
      },
    ]
  }

  useEffect(() => {
    if (!counters) history.push('/')
  }, [counters, history])

  const days: {
    [key: string]: number
  } = { ...counters?.api?.success?.day }
  const monthlyData: {
    date: string
    count: number
  }[] = Object.keys(days)
    .filter((d) => {
      return d.includes(Utils.CurrentMonthSubstring)
    })
    .map((k: string) => ({
      date: k.slice(k.length - 2, k.length),
      count: days[k] as any,
    }))
  const recognized: {
    [key: string]:
      | CountersCommon
      | {
          [key: string]: number
        }
      | number
  } = { ...counters?.api?.success?.recognize }

  const { year, month, day, total, ...documents } = recognized

  const frequencyStats = Object.keys(documents)
    .filter((key: string) => key !== 'alltime')
    .map((key: string) => ({
      docName: key,
      quantity: (documents[key] as CountersCommon).alltime,
    }))

  const renderDbrainServices = () => (
    <div className={style.statusFooter}>
      {status ? (
        'Все сервисы Dbrain работают'
      ) : (
        <>
          Сервисы Dbrain недоступны
          <span>Мы уже в курсе и ведем работу</span>
        </>
      )}
    </div>
  )
  const renderHitlFooter = () => (
    <div className={style.statusFooter}>
      {isHitl ? 'HITL активирован' : 'HITL не активирован'}
    </div>
  )

  return counters ? (
    <div className={style.root}>
      <Container>
        <div className={style.grids}>
          <div className={style.leftGrid}>
            <div className={style.statusA}>
              <StatusWidget
                headerLabels={['ONLINE', 'OFFLINE']}
                status={!!healthcheck}
                renderFooterContent={renderDbrainServices}
              />
            </div>
            <div className={style.statusB}>
              <StatusWidget
                headerLabels={['HITL ON', 'HITL OFF']}
                status={isHitl}
                renderFooterContent={renderHitlFooter}
              />
            </div>
            <div className={style.frequency}>
              <FrequencyWidget stats={[...frequencyStats]} />
            </div>
          </div>
          <div className={style.rightGrid}>
            <MonthlyWidget
              limit={100000}
              total={
                counters.api?.success?.month[Utils.CurrentMonthSubstring] || 0
              }
              data={monthlyData}
            />
            {stats && <TimeWidget stats={getTimeWidgetStats(stats)} />}
          </div>
        </div>
      </Container>
    </div>
  ) : (
    <div>Ошибка</div>
  )
}
