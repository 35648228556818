import {
  ActionTypes,
  SET_APP_ERROR,
  AppError,
  CLEAR_APP_ERROR,
  GET_COUNTERS,
  CountersPayload,
  SET_COUNTERS,
  SET_TOKEN,
  SET_GOOGLE_TOKEN,
  GET_STATS,
  StatsPayload,
  SET_STATS,
  LabelsPayload,
  SET_LABElS,
  GET_LABELS,
  AppDataPayload,
  SET_APP_DATA,
  GET_ALL_COUNTERS,
} from './types'

export function getCounters(): ActionTypes {
  return {
    type: GET_COUNTERS,
  }
}

export function getAllCounters(): ActionTypes {
  return {
    type: GET_ALL_COUNTERS,
  }
}

export function getStats(): ActionTypes {
  return {
    type: GET_STATS,
  }
}

export function setToken(payload: string): ActionTypes {
  return {
    type: SET_TOKEN,
    payload,
  }
}

export function setGoogleToken(payload: string): ActionTypes {
  return {
    type: SET_GOOGLE_TOKEN,
    payload,
  }
}

export function setCounters(payload: CountersPayload): ActionTypes {
  return {
    type: SET_COUNTERS,
    payload,
  }
}

export function setLabels(payload: LabelsPayload): ActionTypes {
  return {
    type: SET_LABElS,
    payload,
  }
}

export function getLabels(): ActionTypes {
  return {
    type: GET_LABELS,
  }
}

export function setStats(payload: StatsPayload): ActionTypes {
  return {
    type: SET_STATS,
    payload,
  }
}

export function setAppData(payload: AppDataPayload): ActionTypes {
  return {
    type: SET_APP_DATA,
    payload,
  }
}

export function setAppError(error: AppError): ActionTypes {
  return {
    type: SET_APP_ERROR,
    payload: error,
  }
}

export function clearAppError(): ActionTypes {
  return {
    type: CLEAR_APP_ERROR,
  }
}
