import React from 'react'
import Widget from '../Widget/Widget'
import style from './TimeWidget.module.css'
import WidgetHeading from '../WidgetHeading/WidgetHeading'

interface TimeWidgetItem {
  label: string
  time: number
}

interface TimeWidgetProps {
  stats: TimeWidgetItem[]
}

export default function TimeWidget({ stats }: TimeWidgetProps) {
  const max = stats.reduce(
    (accum, next) => Math.max(next.time, accum),
    stats[0].time,
  )

  const formatSeconds = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const secs = seconds % 60

    return `${minutes < 10 ? '0' + minutes : minutes}:${
      secs < 10 ? '0' + secs : secs
    }`
  }

  return (
    <Widget>
      <WidgetHeading mb={32}>время распознавания документа</WidgetHeading>
      <div className={style.grid}>
        {stats.map((item, index) => (
          <div className={style.row} key={index}>
            <div className={style.docName}>{item.label}</div>
            <div className={style.time}>{formatSeconds(item.time)}</div>
            <div className={style.barContainer}>
              <div
                className={style.bar}
                style={{ width: `${(item.time / max) * 100}%` }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </Widget>
  )
}
