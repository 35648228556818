import React, { useEffect } from 'react'
import TokenScreen from './components/TokenScreen/TokenScreen'
import Header from './components/Header/Header'
import '@dbrainio/shared-ui/dist/index.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCounters,
  setToken,
  clearAppError,
  getAllCounters,
} from './store/actions'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import StatusScreen from './components/StatusScreen/StatusScreen'
import { AppState, Counters, AppError, Stats } from './store/types'
import style from './App.module.css'
import { atom } from 'jotai'
import AdminScreen from './components/AdminScreen/AdminScreen'

function App() {
  const googleTokenAtom = atom(null)

  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const counters = useSelector<AppState, Counters | undefined>(
    (state: AppState) => state.counters,
  )
  const healthcheck = useSelector<AppState, boolean | undefined>(
    (state: AppState) => state.healthcheck,
  )
  const labels = useSelector<AppState, string[] | undefined>(
    (state: AppState) => state.labels,
  )
  const stats = useSelector<AppState, Stats | undefined>(
    (state: AppState) => state.stats,
  )
  const appError = useSelector<AppState, AppError | undefined>(
    (state: AppState) => state.appError,
  )
  const isLoading = useSelector<AppState, boolean>(
    (state: AppState) => state.isLoading,
  )

  const handleLogin = async (token: string) => {
    dispatch(clearAppError())
    dispatch(setToken(token))
    dispatch(getCounters())
  }

  const handleGoogleLogin = () => {
    dispatch(clearAppError())
    dispatch(getAllCounters())
    history.push('/admin')
  }

  useEffect(() => {
    if (appError === AppError.IncorrectToken) history.push('/')

    return () => {}
  }, [appError, history])

  useEffect(() => {
    if (counters) history.push('/status')
    return () => {}
  }, [counters, history])

  return (
    <div className={style.layout}>
      <div className={style.header}>
        <Header
          transparent={
            location.pathname === '/' || location.pathname === '/token'
          }
        />
      </div>
      <Switch>
        <Route exact path="/status">
          <StatusScreen
            counters={counters}
            stats={stats}
            labels={labels}
            healthcheck={healthcheck}
          />
        </Route>
        <Route exact path="/">
          <TokenScreen
            onGoogleLogin={handleGoogleLogin}
            onLogin={handleLogin}
            isLoading={isLoading}
            isTokenIncorrect={appError === AppError.IncorrectToken}
          />
        </Route>
        <Route exact path="/admin">
          <StatusScreen
            counters={counters}
            stats={stats}
            labels={labels}
            healthcheck={healthcheck}
          />
          {/* <AdminScreen /> */}
        </Route>
        <Route path="*">Страница не найдена</Route>
      </Switch>
    </div>
  )
}

export default App
