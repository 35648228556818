import { call, put, takeLatest, select } from 'redux-saga/effects'
import { Api } from '../api/api'
import { AxiosError, AxiosResponse } from 'axios'
import {
  AppError,
  GET_COUNTERS,
  GetCounters,
  AppState,
  StatsPayload,
  CountersPayload,
  GET_ALL_COUNTERS,
  GetAllCounters,
} from '../store/types'
import { setAppData, setAppError } from '../store/actions'

const transformHttpErrorToAppError = (e: AxiosError) => {
  const { response } = e
  if (response?.status === 403) {
    return AppError.Forbidden
  }

  if (response?.status === 404) return AppError.IncorrectToken

  if (response?.status === 500) {
    return AppError.InternalServerError
  }

  if (response?.status === 503) {
    return AppError.ServiceUnavailable
  }

  return AppError.Other
}

function* getCounters(action: GetCounters) {
  try {
    // TODO: fix type error
    // @ts-expect-error
    const token = yield select((state: AppState) => state.token)
    const counters: AxiosResponse<CountersPayload> = yield call(
      Api.getCounters,
      token,
    )
    const healtcheck: AxiosResponse = yield call(Api.healthcheck)
    const isHealthy = healtcheck.status === 204
    const stats: AxiosResponse<StatsPayload> = yield call(Api.getStats, token)
    const labels: AxiosResponse<string[]> = yield call(Api.getLabels, token)
    yield put(
      setAppData({
        stats: stats.data,
        counters: counters.data,
        healthcheck: isHealthy,
        labels: labels.data,
      }),
    )
    // yield put(push('/status'))
  } catch (e) {
    console.log('function*getCounters -> e', e)
    yield put(setAppError(transformHttpErrorToAppError(e)))
  }
}

function* getAllCounters(action: GetAllCounters) {
  try {
    const counters: AxiosResponse<CountersPayload> = yield call(
      Api.getAllCounters,
    )
    // const healtcheck: AxiosResponse = yield call(Api.healthcheck)
    // const isHealthy = healtcheck.status === 204
    // const stats: AxiosResponse<StatsPayload> = yield call(Api.getStats, token)
    // const labels: AxiosResponse<string[]> = yield call(Api.getLabels, token)
    yield put(
      setAppData({
        // stats: stats.data,
        counters: counters.data,
        // healthcheck: isHealthy,
        // labels: labels.data,
      }),
    )
    // yield put(push('/status'))
  } catch (e) {
    console.log('function*getAllCounters -> e', e)
    yield put(setAppError(transformHttpErrorToAppError(e)))
  }
}

function* AppSaga() {
  yield takeLatest(GET_COUNTERS, getCounters)
  yield takeLatest(GET_ALL_COUNTERS, getAllCounters)
}

export default AppSaga
