import React from 'react'
import Widget from '../Widget/Widget'
import style from './FrequencyWidget.module.css'
import WidgetHeading from '../WidgetHeading/WidgetHeading'

export interface FrequencyWidgetItem {
  docName: string
  quantity: number
}

interface FrequencyWidgetProps {
  stats: FrequencyWidgetItem[]
}

export default function FrequencyWidget({ stats }: FrequencyWidgetProps) {
  const sorted = stats.sort((a, b) => b.quantity - a.quantity)
  const sliced = sorted.slice(0, 5)

  const max = stats.length ? stats[0].quantity : 0

  return (
    <Widget>
      <div className={style.root}>
        <WidgetHeading mb={32}>Самые частые типы документов</WidgetHeading>
        {sliced.length ? (
          <div className={style.grid}>
            {sliced.map((item, index) => (
              <div className={style.row} key={index}>
                <div className={style.docName}>{item.docName}</div>
                <div className={style.quantity}>{item.quantity}</div>
                <div className={style.barContainer}>
                  <div
                    className={style.bar}
                    style={{ width: `${(item.quantity / max) * 100}%` }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={style.fallback}>Недостаточно данных</div>
        )}
      </div>
    </Widget>
  )
}
