import React from 'react'

export default function Logo() {
  return (
    <svg
      width="214"
      height="18"
      viewBox="0 0 214 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60.7632 3.77186C61.3866 3.77186 61.8808 3.59695 62.2382 3.25475C62.5955 2.91254 62.778 2.45627 62.7856 1.88593C62.7856 1.31559 62.6031 0.859313 62.2458 0.517109C61.8884 0.174904 61.3942 0 60.7708 0C60.2006 0 59.7444 0.174904 59.4023 0.517109C59.0601 0.859313 58.8853 1.31559 58.8853 1.88593C58.8853 2.45627 59.0525 2.91254 59.3947 3.25475C59.7292 3.59695 60.193 3.77186 60.7632 3.77186Z"
        fill="#FAFAFA"
      />
      <path
        d="M59.1209 17.7566H62.5271V5.40684H59.1209V17.7566Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.55379 0.121583C9.116 0.121583 11.2677 0.973291 13.0087 2.67671C14.7498 4.38013 15.6166 6.47138 15.6166 8.93526C15.6166 11.4067 14.7498 13.4904 13.0087 15.1938C11.2677 16.8972 9.116 17.7489 6.55379 17.7489H0V0.121583H6.55379ZM3.7863 3.53602V14.3649H6.55379C8.01357 14.3649 9.26046 13.8402 10.2945 12.7908C11.3285 11.7413 11.8455 10.4638 11.8455 8.95047C11.8455 7.43716 11.3437 6.15959 10.3325 5.11017C9.32129 4.06074 8.06679 3.53602 6.5614 3.53602H3.7863Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7432 7.02652C30.9141 8.26606 31.4995 9.78697 31.4995 11.5816C31.4995 13.3763 30.9141 14.8972 29.7356 16.1368C28.5572 17.3763 27.1734 17.9999 25.5844 17.9999C24.8773 17.9999 24.2159 17.8554 23.6076 17.5664C22.9918 17.2774 22.5584 16.9885 22.3075 16.6995L21.935 16.2432V17.7565H18.5288V0.121585H21.9426V6.92005C21.973 6.87442 22.0262 6.80598 22.087 6.73754C22.1554 6.6615 22.2999 6.52461 22.5204 6.3269C22.7409 6.12918 22.9918 5.94667 23.2503 5.78697C23.5088 5.62728 23.8585 5.48279 24.2767 5.35351C24.7025 5.22424 25.1358 5.1634 25.5844 5.1634C27.1886 5.1634 28.5724 5.77937 29.7432 7.02652ZM27.0974 14.0303C27.6828 13.3915 27.9794 12.5778 27.9794 11.5816C27.9794 10.593 27.6828 9.77936 27.0974 9.14058C26.512 8.5018 25.7973 8.19001 24.961 8.19001C24.117 8.19001 23.4099 8.5094 22.8245 9.14058C22.2391 9.77936 21.9426 10.593 21.9426 11.5816C21.9426 12.5702 22.2391 13.3915 22.8245 14.0303C23.4099 14.6691 24.1246 14.9885 24.961 14.9885C25.7973 14.9885 26.512 14.6691 27.0974 14.0303Z"
        fill="#FAFAFA"
      />
      <path
        d="M41.4591 8.68948L41.4667 8.69199H41.4591V8.68948Z"
        fill="#FAFAFA"
      />
      <path
        d="M41.4591 8.68948C41.2082 8.60743 40.8306 8.56264 40.3339 8.55511C39.4367 8.55511 38.7296 8.83648 38.2126 9.40682C37.6956 9.96956 37.4295 10.7832 37.4295 11.8403V17.7566H34.0386V5.40683H37.4295V6.92013C37.5284 6.80607 37.6652 6.65398 37.8477 6.47147C38.0302 6.28896 38.4255 6.04561 39.0338 5.74143C39.642 5.43725 40.2882 5.28516 40.9573 5.28516H41.4591V8.68948Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.5069 5.60464C51.1151 5.90122 51.5485 6.19019 51.807 6.46395L52.1871 6.92023V5.44495H55.5781V17.7567H52.1871V16.2434C52.1567 16.289 52.1035 16.3575 52.0351 16.4335C51.9667 16.5096 51.8146 16.6541 51.5941 16.8518C51.366 17.0495 51.1227 17.232 50.8642 17.3917C50.6057 17.5514 50.2636 17.6959 49.8454 17.8175C49.4272 17.9392 48.9939 18.0001 48.5453 18.0001C46.9487 18.0001 45.5573 17.3765 44.3865 16.1369C43.208 14.8974 42.6226 13.3765 42.6226 11.5818C42.6226 9.78714 43.208 8.26623 44.3865 7.02669C45.5649 5.78715 46.9487 5.16358 48.5453 5.16358C49.2448 5.16358 49.8986 5.30806 50.5069 5.60464ZM51.3052 14.0305C51.8982 13.3917 52.1871 12.578 52.1871 11.5818C52.1871 10.5932 51.8906 9.77954 51.3052 9.14076C50.7122 8.50197 50.0051 8.19019 49.1687 8.19019C48.3324 8.19019 47.6177 8.50958 47.0323 9.14076C46.4393 9.77954 46.1504 10.5932 46.1504 11.5818C46.1504 12.5704 46.4469 13.3917 47.0323 14.0305C47.6253 14.6693 48.3324 14.9887 49.1687 14.9887C49.9975 14.9887 50.7122 14.6693 51.3052 14.0305Z"
        fill="#FAFAFA"
      />
      <path
        d="M69.3392 6.92026C69.438 6.79098 69.5749 6.62369 69.7574 6.43357C69.9398 6.24346 70.3352 5.9773 70.9434 5.6503C71.5517 5.32331 72.1979 5.156 72.867 5.156C74.2735 5.156 75.4292 5.6427 76.3187 6.61608C77.2083 7.58946 77.6493 8.90505 77.6493 10.5628V17.7415H74.2583V10.9431C74.2583 10.1902 74.0378 9.58186 73.5893 9.11798C73.1407 8.6541 72.5705 8.42596 71.8634 8.42596C71.0955 8.42596 70.4796 8.67691 70.0235 9.17881C69.5673 9.68072 69.3392 10.3955 69.3392 11.3233V17.7415H65.9482V5.40696H69.3392V6.92026Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.78 0.120117V17.7421H92.5056C95.1462 17.7421 97.3933 16.8818 99.2341 15.1491C101.062 13.4287 101.989 11.352 101.989 8.93109C101.989 6.52251 101.062 4.44573 99.2341 2.71302C97.3933 0.992612 95.1462 0.120117 92.5056 0.120117H86.78ZM92.5056 1.88968C94.6384 1.88968 96.4284 2.57785 97.8757 3.94189C99.323 5.31822 100.047 6.97719 100.047 8.94337C100.047 10.9096 99.323 12.5685 97.8757 13.9326C96.4284 15.3089 94.6384 15.9848 92.5056 15.9848H88.7351V1.88968H92.5056Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.989 7.55476L114.811 7.32127C114.684 7.17381 114.494 7.00177 114.24 6.78057C113.986 6.57166 113.694 6.36276 113.351 6.16614C113.021 5.96952 112.602 5.78519 112.107 5.63773C111.612 5.49026 111.092 5.41653 110.558 5.41653C108.971 5.41653 107.575 6.03096 106.382 7.27212C105.188 8.50098 104.592 9.98791 104.592 11.7083C104.592 13.4287 105.188 14.9157 106.382 16.1445C107.575 17.3734 108.971 18.0001 110.558 18.0001C111.092 18.0001 111.612 17.9264 112.12 17.7789C112.628 17.6315 113.034 17.4594 113.351 17.2628C113.681 17.0662 113.973 16.8573 114.253 16.6115C114.532 16.3657 114.722 16.1814 114.799 16.0831C114.887 15.9848 114.951 15.911 114.989 15.8619V17.7543H116.804V5.67H114.989V7.55476ZM114.989 11.7083C114.989 12.9495 114.532 14.0309 113.618 14.9648C112.704 15.8988 111.688 16.3534 110.558 16.3534C109.492 16.3534 108.527 15.911 107.676 15.0017C106.826 14.0923 106.394 12.9986 106.394 11.7083C106.394 10.418 106.826 9.32432 107.676 8.41496C108.527 7.5056 109.492 7.05092 110.558 7.05092C111.688 7.05092 112.704 7.51789 113.618 8.45183C114.532 9.38577 114.989 10.4672 114.989 11.7083Z"
        fill="#FAFAFA"
      />
      <path
        d="M128.446 6.86659L128.636 7.05092L127.329 8.31665C127.253 8.21834 127.151 8.09546 127.024 7.96028C126.897 7.82511 126.592 7.64078 126.123 7.40729C125.64 7.17381 125.133 7.06321 124.599 7.06321C123.901 7.06321 123.342 7.23525 122.911 7.59162C122.479 7.9357 122.263 8.35352 122.263 8.82049C122.263 9.29975 122.492 9.69298 122.962 9.98791C123.419 10.2828 123.99 10.5163 124.65 10.6884C125.31 10.8604 125.97 11.0693 126.631 11.2905C127.291 11.5117 127.849 11.8804 128.319 12.3965C128.776 12.9126 129.017 13.5639 129.017 14.3627C129.017 15.3949 128.598 16.2674 127.786 16.9556C126.961 17.6437 125.856 18.0001 124.472 18.0001C123.876 18.0001 123.317 17.9264 122.797 17.7789C122.276 17.6315 121.844 17.4471 121.514 17.2505C121.172 17.0416 120.88 16.8327 120.626 16.6238C120.372 16.4149 120.194 16.2428 120.08 16.0954L119.915 15.8619L121.222 14.6084C121.248 14.6576 121.299 14.719 121.362 14.7928C121.413 14.8665 121.552 15.014 121.756 15.2106C121.959 15.4072 122.187 15.5915 122.428 15.7513C122.657 15.911 122.962 16.0585 123.342 16.1814C123.711 16.3043 124.092 16.3657 124.472 16.3657C125.348 16.3657 126.021 16.1691 126.491 15.8004C126.961 15.4195 127.202 14.9402 127.202 14.3627C127.202 13.8588 126.961 13.4533 126.491 13.1338C126.021 12.8143 125.463 12.5685 124.802 12.3842C124.142 12.1999 123.495 11.991 122.835 11.7698C122.175 11.5486 121.603 11.1922 121.134 10.7007C120.664 10.2091 120.435 9.58239 120.435 8.8082C120.435 7.89884 120.829 7.11236 121.616 6.4242C122.403 5.74832 123.406 5.40424 124.599 5.40424C125.082 5.40424 125.551 5.46568 126.009 5.57628C126.466 5.68688 126.846 5.82206 127.151 5.98181C127.443 6.14156 127.71 6.30131 127.938 6.47335C127.999 6.51945 128.057 6.56202 128.11 6.60153C128.257 6.70949 128.372 6.79462 128.446 6.86659Z"
        fill="#FAFAFA"
      />
      <path
        d="M134.21 0.120117H132.394V17.7421H134.21V11.0693C134.21 9.90189 134.578 8.94337 135.327 8.19377C136.063 7.43187 136.99 7.06321 138.107 7.06321C139.097 7.06321 139.91 7.38271 140.545 8.02172C141.179 8.66074 141.497 9.52094 141.497 10.5778V17.7543H143.312V10.5778C143.312 9.01711 142.842 7.76366 141.903 6.81744C140.964 5.8835 139.745 5.40424 138.234 5.40424C137.726 5.40424 137.244 5.47797 136.774 5.62544C136.317 5.7729 135.936 5.93265 135.644 6.10469C135.365 6.27674 135.098 6.48564 134.844 6.71913C134.591 6.9649 134.438 7.12465 134.362 7.21067C134.317 7.26826 134.282 7.3132 134.252 7.35042L134.221 7.38887L134.197 7.41958L134.21 0.120117Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.603 0.120117H149.419V7.56704C149.444 7.51789 149.508 7.43187 149.596 7.33356C149.685 7.23525 149.863 7.06321 150.142 6.81744C150.422 6.57166 150.714 6.35047 151.044 6.15385C151.361 5.95723 151.767 5.78519 152.275 5.63773C152.783 5.49026 153.303 5.41653 153.837 5.41653C155.424 5.41653 156.82 6.03096 158.013 7.27212C159.207 8.50098 159.816 9.98791 159.816 11.7083C159.816 13.4287 159.219 14.9157 158.026 16.1445C156.833 17.3734 155.436 17.9878 153.837 17.9878C153.303 17.9878 152.783 17.9141 152.288 17.7666C151.793 17.6192 151.374 17.4471 151.044 17.2382C150.701 17.0416 150.409 16.8327 150.155 16.6238C149.901 16.4149 149.723 16.2305 149.609 16.0831L149.419 15.8496V17.7421H147.603V0.120117ZM150.777 8.45183C149.863 9.38577 149.419 10.4672 149.419 11.7083C149.419 12.9495 149.863 14.0309 150.777 14.9648C151.691 15.8988 152.707 16.3534 153.837 16.3534C154.903 16.3534 155.868 15.911 156.718 15.0017C157.569 14.0923 158.001 12.9986 158.001 11.7083C158.001 10.418 157.569 9.32432 156.718 8.41496C155.868 7.5056 154.903 7.05092 153.837 7.05092C152.707 7.05092 151.691 7.51789 150.777 8.45183Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M162.152 11.7083C162.152 13.4779 162.761 14.9648 164.006 16.1814C165.25 17.3857 166.748 18.0001 168.525 18.0001C170.29 18.0001 171.788 17.3857 173.019 16.1814C174.263 14.9648 174.873 13.4779 174.873 11.7083C174.873 9.93876 174.263 8.45183 173.019 7.23525C171.788 6.01867 170.29 5.40424 168.525 5.40424C166.748 5.40424 165.25 6.01867 164.006 7.23525C162.761 8.45183 162.152 9.93876 162.152 11.7083ZM173.07 11.7083C173.07 13.0109 172.613 14.1169 171.724 15.014C170.836 15.911 169.757 16.3534 168.512 16.3534C167.268 16.3534 166.189 15.911 165.301 15.0017C164.412 14.0923 163.967 12.9986 163.967 11.696C163.967 10.3934 164.412 9.29975 165.301 8.40267C166.189 7.5056 167.268 7.05092 168.512 7.05092C169.757 7.05092 170.836 7.5056 171.724 8.40267C172.613 9.29975 173.07 10.4057 173.07 11.7083Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.454 7.32127L187.632 7.55476V5.67H189.447V17.7543H187.632V15.8619C187.593 15.911 187.53 15.9848 187.441 16.0831C187.365 16.1814 187.175 16.3657 186.895 16.6115C186.616 16.8573 186.324 17.0662 185.994 17.2628C185.676 17.4594 185.27 17.6315 184.762 17.7789C184.255 17.9264 183.734 18.0001 183.201 18.0001C181.614 18.0001 180.217 17.3734 179.024 16.1445C177.831 14.9157 177.234 13.4287 177.234 11.7083C177.234 9.98791 177.831 8.50098 179.024 7.27212C180.217 6.03096 181.614 5.41653 183.201 5.41653C183.734 5.41653 184.255 5.49026 184.75 5.63773C185.245 5.78519 185.664 5.96952 185.994 6.16614C186.337 6.36276 186.629 6.57166 186.883 6.78057C187.136 7.00177 187.327 7.17381 187.454 7.32127ZM186.26 14.9648C187.175 14.0309 187.632 12.9495 187.632 11.7083C187.632 10.4672 187.175 9.38577 186.26 8.45183C185.346 7.51789 184.331 7.05092 183.201 7.05092C182.134 7.05092 181.17 7.5056 180.319 8.41496C179.468 9.32432 179.037 10.418 179.037 11.7083C179.037 12.9986 179.468 14.0923 180.319 15.0017C181.17 15.911 182.134 16.3534 183.201 16.3534C184.331 16.3534 185.346 15.8988 186.26 14.9648Z"
        fill="#FAFAFA"
      />
      <path
        d="M200.238 5.40424H199.717C199.172 5.40424 198.651 5.47797 198.169 5.61315C197.686 5.74832 197.293 5.89579 197.001 6.06783C196.709 6.23987 196.442 6.4242 196.188 6.6454C195.934 6.86659 195.769 7.01405 195.706 7.10008C195.63 7.1861 195.579 7.25983 195.553 7.30898V5.67459H193.738V17.7543H195.553V11.0816C195.553 9.92647 195.934 8.98024 196.696 8.2675C197.458 7.54247 198.372 7.1861 199.451 7.1861H200.238V5.40424Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M212.007 7.32127L212.184 7.55476V0.132406H214V17.7543H212.184V15.8619C212.146 15.911 212.083 15.9848 211.994 16.0831C211.918 16.1814 211.727 16.3657 211.448 16.6115C211.169 16.8573 210.877 17.0662 210.547 17.2628C210.229 17.4594 209.823 17.6315 209.315 17.7789C208.807 17.9264 208.287 18.0001 207.754 18.0001C206.167 18.0001 204.77 17.3734 203.577 16.1445C202.384 14.9157 201.787 13.4287 201.787 11.7083C201.787 9.98791 202.384 8.50098 203.577 7.27212C204.77 6.03096 206.167 5.41653 207.754 5.41653C208.287 5.41653 208.807 5.49026 209.302 5.63773C209.798 5.78519 210.217 5.96952 210.547 6.16614C210.889 6.36276 211.181 6.57166 211.435 6.78057C211.689 7.00177 211.88 7.17381 212.007 7.32127ZM210.813 14.9648C211.727 14.0309 212.184 12.9495 212.184 11.7083C212.184 10.4672 211.727 9.38577 210.813 8.45183C209.899 7.51789 208.884 7.05092 207.754 7.05092C206.687 7.05092 205.722 7.5056 204.872 8.41496C204.021 9.32432 203.59 10.418 203.59 11.7083C203.59 12.9986 204.021 14.0923 204.872 15.0017C205.722 15.911 206.687 16.3534 207.754 16.3534C208.884 16.3534 209.899 15.8988 210.813 14.9648Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
