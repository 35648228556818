import React, { useState, FormEvent } from 'react'
import Container from '@material-ui/core/Container'
import style from './TokenScreen.module.css'
import {
  Button,
  ButtonTheme,
  TextInput,
  Checkbox,
  Text,
  TextTheme,
} from '@dbrainio/shared-ui'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'
import { LocalStorage } from '../../utils/localStorage'
import GoogleLoginButton from '../GoogleLoginButton/GoogleLoginButton'

interface TokenScreenProps {
  isLoading?: boolean
  isTokenIncorrect?: boolean
  onLogin: (token: string) => void
  onGoogleLogin: () => void
}

export default function TokenScreen({
  onLogin,
  onGoogleLogin,
  isLoading,
  isTokenIncorrect,
}: TokenScreenProps) {
  const [token, setToken] = useState<string>(LocalStorage.getToken() || '')
  const [remember, setRemember] = useState<boolean>(true)
  const history = useHistory()
  const handleTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToken(e.target.value)
  }

  const handleLogin = () => {
    onLogin(token)

    if (remember) LocalStorage.setToken(token)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleLogin()
  }

  const handleGoogle = () => {
    // history.push('/admin')
    onGoogleLogin()
  }

  return (
    <div className={style.root}>
      <Container>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <form action="" onSubmit={handleSubmit}>
              <label htmlFor="token" className={style.label}>
                Введите токен
              </label>
              <div className={style.textInput}>
                <TextInput
                  onChange={handleTokenChange}
                  value={token}
                  name="token"
                  className={style.input}
                  style={{ color: isTokenIncorrect ? 'red' : '#000' }}
                />
                {isTokenIncorrect && (
                  <span className={style.incorrect}>Неверный токен</span>
                )}
              </div>
              <div className={style.enter}>
                <Button
                  theme={ButtonTheme.Blue}
                  disabled={!token.length}
                  isLoading={isLoading}
                  className={style.submit}
                  type="submit"
                >
                  <span className={style.buttonText}>Войти</span>
                </Button>
                <span className={style.googleButton}>
                  <GoogleLoginButton onLogin={handleGoogle} />
                </span>
                <div
                  onClick={() => setRemember(!remember)}
                  className={style.checkboxArea}
                >
                  <div className={style.checkbox}>
                    <Checkbox onChange={() => {}} isChecked={remember} />
                  </div>
                  <Text theme={TextTheme.Standard}>Запомнить меня</Text>
                </div>
              </div>
            </form>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
