export enum LocalStorageItem {
  Token = 'dashboard-token',
  GoogleToken = 'google-token-id',
}

export class LocalStorage {
  static setToken(token: string): void {
    localStorage.setItem(LocalStorageItem.Token, token)
  }
  static getToken(): string | null {
    return localStorage.getItem(LocalStorageItem.Token)
  }
  static setGoogleToken(token: string): void {
    localStorage.setItem(LocalStorageItem.GoogleToken, token)
  }
  static cleanGoogleToken(): void {
    localStorage.removeItem(LocalStorageItem.GoogleToken)
  }
  static getGoogleToken(): string | null {
    return localStorage.getItem(LocalStorageItem.GoogleToken)
  }
}
