import axios, { AxiosResponse } from 'axios'
import {
  GetCountersResponse,
  GetLabelsResponse,
  GetStatsResponse,
} from '../@types/api'

const licenseHost = window._env_.LICENSE_HOST || ''
// const licenseHost = 'http://license.ml.dbrain.io'
const statsHost = window._env_.STATS_HOST
// || 'https://stats.lb.dbrain.io'
const healthcheckHost = window._env_.HEALTCHECK_HOST
//  || 'https://latest.dbrain.io'

export class Api {
  static async getCounters(token: string) {
    const response = axios.get<GetCountersResponse>(
      `${licenseHost}/licenses/${token}/counters`,
    )
    return response
  }

  static async getAllCounters() {
    const response = axios.get<GetCountersResponse>(
      `${licenseHost}/licenses/counters`,
    )
    return response
  }

  static async healthcheck() {
    const response = axios.get<GetCountersResponse>(
      `${healthcheckHost}/healthcheck`,
    )
    return response
  }

  static async getLabels(token: string) {
    const response = axios.get<GetLabelsResponse>(
      `${licenseHost}/licenses/${token}/labels`,
    )
    return response
  }

  static async getStats(token: string) {
    const response = axios.get<GetStatsResponse>(`${statsHost}/recognize`, {
      params: {
        token,
      },
    })
    return response
  }
}
